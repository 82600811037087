<template>
  <PageLayout title="Search" linkDefault1="/">
    <Search @change="setResults($event)" v-bind:searches="currentSearch" />
    <div :key="update">
      <div class="pt-5 px-4 pb-6 overflow-scroll scrollbar-hide">
        <transition-group tag="ul" name="scale-up" class="relative flex shrink-0 flex-col items-center space-y-4">
          <li v-for="(_class, i) in results.classes" :key="i">
            <ClassCard :_class="_class" selectable @select="callback(_class)" />
          </li>
        </transition-group>
      </div>
    </div>
  </PageLayout>
</template>
  
<script>
import Search from "../../components/Feed/Search.vue";

import FeedsAPI from "../../../FeedsAPI";
import ClassCard from "../../components/Feed/ClassCard.vue";
import PageLayout from "../../components/base/pageLayout.vue";

import { find } from "../../helpers/getters"

export default {
  name: "Tags Search",
  components: {
    Search,
    ClassCard,
    PageLayout
  },
  data() {
    return {
      all_classes: this.$store.state.user.university?.classes.map(c => {
        return {
          ...c,
          university: this.$store.state.user.university
        }
      }),
      results: {},
      update: 0,
      currentSearch: [
        {
          API: FeedsAPI,
          category: "classes",
          onChange: async (value) => {
            if (!value) return [];
            return find(value, this.all_classes, "name").slice(0, 8);
          }
        },
      ],
    };
  },
  methods: {
    callback(_class) {
      this.$store.state.classSearchCallback(_class, this.$store.state.user.classes)
    },
    setResults(res) {
      this.results.classes = res.classes
      this.update++;
    },
  },
};
</script>